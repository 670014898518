<div class="inline-flex w-full flex-col items-start justify-start gap-1">
	@if (label) {
		<label
			class="font-futura text-xs font-medium text-neutral-400 lg:text-sm"
			[class.invalid]="ngControl.invalid && ngControl.touched"
			[class.focused]="hasFocus"
		>
			{{ label }}
		</label>
	}
	<mat-form-field
		[class.solid]="fill === 'solid'"
		[class.translucid]="fill === 'translucid'"
		[class.empty]="fill === 'empty'"
		[class.cursor-pointer]="cursor === 'pointer'"
		appearance="outline"
		subscriptSizing="dynamic"
	>
		@if (prefixIcon) {
			<cp-common-icon
				class="text-2xl"
				[name]="prefixIcon"
				[isFilled]="true"
				[class]="iconColor"
				matPrefix
			></cp-common-icon>
		}
		<input
			class="!font-futura !text-sm !font-medium !text-zinc-600 lg:!text-base"
			[class.cursor-pointer]="cursor === 'pointer'"
			[formControl]="ngControl.control"
			[placeholder]="placeholderOrLabel"
			[type]="type"
			[matAutocomplete]="auto"
			(focus)="onFocus()"
			(blur)="onBlur()"
			matInput
		/>
		<mat-autocomplete
			#auto="matAutocomplete"
			[displayWith]="displayFn"
			(optionSelected)="emitOptionSelected($event)"
		>
			@for (option of filteredOptions | async; track option) {
				<mat-option [value]="option">{{ displayFormatOption(option) }}</mat-option>
			}
		</mat-autocomplete>
		@if (suffixIcon) {
			<cp-common-icon
				class="text-2xl"
				[name]="suffixIcon"
				[isFilled]="true"
				[class]="iconColor"
				matSuffix
			></cp-common-icon>
		}
		<mat-error class="font-futura pt-2 text-xs font-medium text-rose-500 lg:text-sm">
			<!-- Generic errors -->
			@if (ngControl.hasError("required")) {
				@if (specialType === "ca") {
					<span class="inline-flex">
						Veuillez entrer 6 caractères minimum. <br />
						Compléter avec autant de caractère "0" devant votre numéro de CA pour atteindre un nombre de 6.
						<br />
						Exemple : si votre numéro de CA est 24, alors entrez 000024.</span
					>
				} @else {
					<span class="inline-flex">Le champ "{{ name ?? label }}" est obligatoire.</span>
				}
			}
		</mat-error>
	</mat-form-field>
</div>
