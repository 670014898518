export class SystemPayParameterKeys {
	static readonly actionMode: string = "vads_action_mode";
	static readonly amount: string = "vads_amount";
	static readonly contextMode: string = "vads_ctx_mode";
	static readonly currency: string = "vads_currency";
	static readonly customerEmail: string = "vads_cust_email";
	static readonly customerId: string = "vads_cust_id";
	static readonly pageAction: string = "vads_page_action";
	static readonly paymentConfig: string = "vads_payment_config";
	static readonly returnMode: string = "vads_return_mode";
	static readonly returnUrl: string = "vads_url_return";
	static readonly signature: string = "signature";
	static readonly siteId: string = "vads_site_id";
	static readonly transactionStatus: string = "vads_trans_status";
	static readonly transmissionDate: string = "vads_trans_date";
	static readonly transmissionId: string = "vads_trans_id";
	static readonly urlCheck: string = "vads_url_check";
	static readonly version: string = "vads_version";

	// Warning: Order is important !
	static readonly allRequest: string[] = [
		SystemPayParameterKeys.actionMode,
		SystemPayParameterKeys.amount,
		SystemPayParameterKeys.contextMode,
		SystemPayParameterKeys.currency,
		SystemPayParameterKeys.customerEmail,
		SystemPayParameterKeys.customerId,
		SystemPayParameterKeys.pageAction,
		SystemPayParameterKeys.paymentConfig,
		SystemPayParameterKeys.returnMode,
		SystemPayParameterKeys.siteId,
		SystemPayParameterKeys.transmissionDate,
		SystemPayParameterKeys.transmissionId,
		SystemPayParameterKeys.returnUrl,
		SystemPayParameterKeys.urlCheck,
		SystemPayParameterKeys.version,
		SystemPayParameterKeys.signature,
	];
}

export class OnlinePaymentTransactionStatus {
	static readonly error = "error";
	static readonly expiration = "expiration";
	static readonly failure = "failure";
	static readonly none = "none";
	static readonly refusal = "refusal";
	static readonly success = "success";
}

export class SystemPayTransmissionResponseStatus {
	static readonly abandoned = "ABANDONED";
	static readonly authorised = "AUTHORISED";
	static readonly authorisedToValidate = "AUTHORISED_TO_VALIDATE";
	static readonly cancelled = "CANCELLED";
	static readonly captured = "CAPTURED";
	static readonly captureFailed = "CAPTURE_FAILED";
	static readonly expired = "EXPIRED";
	static readonly initial = "INITIAL";
	static readonly notCreated = "NOT_CREATED";
	static readonly refused = "REFUSED";
	static readonly suspended = "SUSPENDED";
	static readonly underVerification = "UNDER_VERIFICATION";
	static readonly waitingAuthorisation = "WAITING_AUTHORISATION";
	static readonly waitingAuthorisationToValidate = "WAITING_AUTHORISATION_TO_VALIDATE";

	static readonly record: Record<string, string> = {
		[SystemPayTransmissionResponseStatus.abandoned]: OnlinePaymentTransactionStatus.none,
		[SystemPayTransmissionResponseStatus.authorised]: OnlinePaymentTransactionStatus.success,
		[SystemPayTransmissionResponseStatus.authorisedToValidate]: OnlinePaymentTransactionStatus.success,
		[SystemPayTransmissionResponseStatus.cancelled]: OnlinePaymentTransactionStatus.failure,
		[SystemPayTransmissionResponseStatus.captureFailed]: OnlinePaymentTransactionStatus.error,
		[SystemPayTransmissionResponseStatus.expired]: OnlinePaymentTransactionStatus.failure,
		[SystemPayTransmissionResponseStatus.initial]: OnlinePaymentTransactionStatus.expiration,
		[SystemPayTransmissionResponseStatus.notCreated]: OnlinePaymentTransactionStatus.failure,
		[SystemPayTransmissionResponseStatus.refused]: OnlinePaymentTransactionStatus.refusal,
		[SystemPayTransmissionResponseStatus.suspended]: OnlinePaymentTransactionStatus.none,
		[SystemPayTransmissionResponseStatus.underVerification]: OnlinePaymentTransactionStatus.none,
		[SystemPayTransmissionResponseStatus.waitingAuthorisation]: OnlinePaymentTransactionStatus.success,
		[SystemPayTransmissionResponseStatus.waitingAuthorisationToValidate]: OnlinePaymentTransactionStatus.success,
	};
}
