<button
	class="inline-flex items-center justify-center gap-2 rounded-full py-2 focus-visible:outline-none sm:py-3"
	[disabled]="disabled"
	[matRippleDisabled]="disabled"
	[ngClass]="{
		'bg-primary text-white': !isReversed,
		'text-primary border-primary border bg-white': isReversed,
		'w-full': isFullWidth,
		'opacity-40': disabled || readOnly,
		'px-4 sm:px-6': label,
		'px-2 sm:px-3': !label,
		'pointer-events-none': disabled
	}"
	[type]="type"
	(click)="handleClick()"
	matRipple
>
	@if (label) {
		<div class="font-intro text-sm font-bold leading-6 sm:text-base">{{ label | uppercase }}</div>
	}
	@if (icon) {
		<cp-common-icon class="text-2xl" [name]="icon" [isFilled]="isIconFilled"></cp-common-icon>
	}
</button>
