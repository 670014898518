import { ChangeDetectionStrategy, Component, Input, inject } from "@angular/core";
import { FormControlDirective, FormControlName, NgControl } from "@angular/forms";
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule } from "@angular/material/form-field";
import { NoopValueAccessorDirective } from "./noop-value-accessor/noop-value-accessor.directive";

@Component({
	selector: "cp-base-control-input",
	template: "",
	hostDirectives: [NoopValueAccessorDirective],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [MatFormFieldModule],
	providers: [
		{
			provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
			useValue: {
				subscriptSizing: "dynamic",
			},
		},
	],
})
export class BaseControlInputComponent {
	@Input() label?: string;
	@Input() name?: string;
	@Input() placeholder?: string;
	@Input() fill: "solid" | "translucid" | "empty" | "dashed" = "translucid";

	get placeholderOrLabel(): string {
		if (this.placeholder) {
			return this.placeholder;
		}

		return this.label ? this.label : "";
	}

	ngControl = this.injectNgControl();

	private injectNgControl(): FormControlDirective | FormControlName {
		const ngControl = inject(NgControl, { self: true, optional: true });
		if (!ngControl) throw new Error("...");
		if (ngControl instanceof FormControlDirective || ngControl instanceof FormControlName) {
			return ngControl;
		}
		throw new Error("...");
	}
}
