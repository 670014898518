import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatRippleModule } from "@angular/material/core";
import { MatIconModule } from "@angular/material/icon";
import { CommonIconModule } from "@e-tenant-hub/client-platform/shared/ui/responsive/common-icon";
import { RoundedButtonComponent } from "./rounded-button.component";

@NgModule({
	imports: [CommonModule, MatIconModule, MatRippleModule, CommonIconModule],
	declarations: [RoundedButtonComponent],
	exports: [RoundedButtonComponent],
})
export class RoundedButtonModule {}
